import React from 'react'
import './style.scss'
import { Link } from 'gatsby'


function CustomBlock({ fields: field }) {

	function validURL(str) {
		var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
		return !!pattern.test(str);
	}

	return (
		<div className="box grid-12 span-12-tablet">
			<div className="button-container span-12 ">
				{field?.map((item, i) => {
					if (item.field) {
						return (
							validURL(item.field) ?
								<a key={i} href={item.field} target='_blank'><span className="button">{item.id}</span></a>
								:
								<Link key={i} to={item.field}><span className="button">{item.id}</span></Link>
						)
					}
				})}
				{/* <a href="https://portal.mru.edu/Applicant/InfoRequest.aspx" target="_blank" rel="noreferrer"><span className="button">Apply now</span></a>
				<Link to="/directory"><span className="button">Meet our Faculty</span></Link> */}
			</div>
		</div>
	)
}

export default CustomBlock