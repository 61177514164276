import React from 'react'
import './style.scss'

function MRUFacilitiesModal() {

	return (
		<div id="facilities-life-modal" className=" c5 ">
			<iframe
				src="https://www.youtube.com/embed/S2PHiu3-0yQ?si=bSDt3r0K1F-MPmMD?autoplay=1"
				title="YouTube video player"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				referrerpolicy="strict-origin-when-cross-origin"
				allowfullscreen
				style={{ width: '100%', height: '100%', position:'absolute', top: '0' }}
			/>
		</div>
	)
}

export default MRUFacilitiesModal