import React from 'react'
import { useInView } from 'react-intersection-observer'
import { graphql } from "gatsby"
// import Img from 'gatsby-image'
import ISAdmissions from '../components/InternationalStudents/Admissions'
import ISGlimpse from '../components/InternationalStudents/Glimpse'
import ISPrograms from '../components/InternationalStudents/Programs'
import VideoHTML from '../components/Video/HTML'
import ISTeam from '../components/InternationalStudents/Team'
import ISImageLinks from '../components/InternationalStudents/ImageLinks'
import ISDiverse from '../components/InternationalStudents/Diverse'
import ISInfo from '../components/InternationalStudents/Info'
import ISFAQ from '../components/InternationalStudents/FAQ'
import Modal from '../components/Modal'
import MRUTuitionModal from '../components/Modal/MRUTuition'
import MRUHousingModal from '../components/Modal/MRUHousing'
import MRUCampusLifeModal from '../components/Modal/MRUCampusLife'

import SEO from '../components/seo'
import Footer from '../components/Footer'
import FormDetail from '../components/Content/ACF/Custom/FormDetail'
import ISInternships from '../components/InternationalStudents/Internships'
import MRUFacilitiesModal from '../components/Modal/MRUFacilities'

const InternationalStudentsPage = ({ data, pageContext }) => {
  const [io, ioInView] = useInView({ triggerOnce: true })
  const { wordpressPage, wordpressAcfOptions } = data
  const { acf } = wordpressPage
  const footerImage = wordpressAcfOptions.options.footer_image?.localFile?.childImageSharp.fluid

  return (
    <>
      <div id="mainframe-cover" className="layer">
        <div id="mainframe-bg-overlay" className="layer"></div>
        <div id="mainframe-stripes" className="layer"></div>
      </div>

      <Modal style="international-students tuition-modal" trigger={"#modal-mru-tuition"}>
        <MRUTuitionModal />
      </Modal>

      <Modal style="international-students" trigger={"#modal-mru-housing"}>
        <MRUHousingModal {...acf} />
      </Modal>

      <Modal style="international-students" trigger={"#modal-mru-campus-life"}>
        <MRUCampusLifeModal {...acf} />
      </Modal>

			<Modal style="international-students facilities" trigger={"#modal-mru-facilities"}>
        <MRUFacilitiesModal {...acf} />
      </Modal>

      <SEO title={"International Students"} description="The Office of International Students is here to guide each of you through the process of studying in the United States. Our Team will assist you with the wide range of essential and practical steps you will need as you transition to our university." />


      <main id="mainframe" className={`c0 main-${pageContext.slug === "/" ? "frontpage" : pageContext.slug}`}>

        <section ref={io} id='section-hero-is' className={`content is-inview ${ioInView ? 'inview' : ''} hero`}>

          <div id={"hero-is"} className="anchor"></div>

          {/* <div className='block block-image x1'>
          <div className='image-wrap'>
            <Img fluid={acf.hero_image.localFile.childImageSharp.fluid}
              imgStyle={{ objectFit: 'cover' }}
              objectPosition='50% 50%'
              className='image-main'
            />
          </div>
          <div className='bg-overlay' style={{ opacity: 0.2 }} />
          <div class="attached-to-image">
            <div class="wysiwyg-inject animated collapse">
              <h1>International Students</h1>
            </div>
          </div>
        </div> */}
          <div className='block block-video x1 autoplay'>
            <VideoHTML autoplay={true} revealonclick={false} file={acf.hero_video.localFile.publicURL} />
            <div className='bg-overlay' style={{ opacity: 0.2 }} />

            <div style={{ zIndex: 500 }} className="attached-to-video">
              <div className="wysiwyg-inject animated collapse">
                <h1>International Students</h1>

              </div>

            </div>
          </div>

          <div id="header-fold-breakpoint"></div>

        </section>

        <ISPrograms {...acf} />

        <section ref={io} id='section-video-is' className={`content is-inview ${ioInView ? 'inview' : ''} `}>
          <div className='block block-video'>
            <VideoHTML autoplay={true} revealonclick={true} buttonText={"<h1> </h1>"} file={acf.showcase_video.localFile.publicURL} />
          </div>
        </section>

        <ISAdmissions data={acf} />
        <ISGlimpse data={acf} />
        <ISInfo {...acf} />
        {/* <ISInternships {...acf} /> */}
        <ISDiverse {...acf} />
        <section ref={io} id='section-video-is' className={`content is-inview ${ioInView ? 'inview' : ''} `}>
          <div className='block block-video'>
            <VideoHTML autoplay={true} revealonclick={true} buttonText={"<h1> </h1>"} file={acf.showcase_video_2.localFile.publicURL} />
          </div>
        </section>

        <ISImageLinks {...acf} />
        <ISFAQ {...acf} />
        <ISTeam {...acf} />

        <section className='c0 inview padd-top-2 padd-bottom'>
          <div id="block-custom-request-information" className="block block-custom span-6 span-12-mobile">
            <FormDetail fields={"Request information"} />
          </div>
        </section>

        <Footer image={footerImage} />
      </main>

    </>
  )
}

export default InternationalStudentsPage


export const query = graphql`
  query {
    wpgraphql {
      ...wpNaviPrimary
      ...wpNaviSecondary
    }
    wordpressAcfOptions {
    options {
      footer_image {
        localFile {
          ...imgStandard
        }
      }
    }
  },
  wordpressPage(slug: {eq: "international-students"}) {
    id
    acf {
      hero_image {
        localFile {
          childImageSharp {
            fluid (
              maxWidth: 1280,
              quality: 80,
              srcSetBreakpoints: [960,1280,1920,2560]
            ) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      hero_video {
        localFile {
          publicURL
        }
      }
      mru_international_logo {
        localFile {
          childImageSharp {
            fluid (
              maxWidth: 2560,
              quality: 80,
              srcSetBreakpoints: [960,1280,1920,2560]
            ) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      admissions_backdrop_desktop {
        localFile {
          childImageSharp {
            fluid (
              maxWidth: 2560,
              quality: 80,
              srcSetBreakpoints: [960,1280,1920,2560]
            ) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      admissions_backdrop_mobile {
        localFile {
          childImageSharp {
            fluid (
              maxWidth: 2560,
              quality: 80,
              srcSetBreakpoints: [960,1280,1920,2560]
            ) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      mru_international_backdrop {
        localFile {
          childImageSharp {
            fluid (
              maxWidth: 2560,
              quality: 80,
              srcSetBreakpoints: [960,1280,1920,2560]
            ) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      zucca_mascot_image_2 {
        localFile {
          childImageSharp {
            fluid (
              maxWidth: 1600,
              quality: 80,
              srcSetBreakpoints: [960,1280,1920,2560]
            ) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      slider_backdrop_image {
        localFile {
          childImageSharp {
            fluid (
              maxWidth: 2560,
              quality: 80,
              srcSetBreakpoints: [960,1280,1920,2560]
            ) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      glimpse_of_us_slider {
        slider_image {
          description
          localFile {
            childImageSharp {
              fluid (
                maxWidth: 2048,
                quality: 80,
                srcSetBreakpoints: [960,1280,1920,2560]
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      zucca_mascot_image_1 {
        localFile {
          childImageSharp {
            fluid (
              maxWidth: 1280,
              quality: 80,
              srcSetBreakpoints: [960,1280,1920,2560]
            ) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      program_links {
        link
        title
        image {
          localFile {
            childImageSharp {
              fluid (
                maxWidth: 1280,
                quality: 80,
                srcSetBreakpoints: [960,1280,1920,2560]
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      image_links_section {
        link
        title
        image {
          localFile {
            childImageSharp {
              fluid (
                maxWidth: 1280,
                quality: 80,
                srcSetBreakpoints: [960,1280,1920,2560]
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      showcase_video {
        localFile {
          publicURL
        }
      }
      showcase_video_2 {
        localFile {
          publicURL
        }
      }
      team_ {
        image {
          localFile {
            childImageSharp {
              fluid (
                maxWidth: 1280,
                quality: 80,
                srcSetBreakpoints: [960,1280,1920,2560]
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        subtitle
        title
      }
      graduate_image {
        localFile {
          childImageSharp {
            fluid (
              maxWidth: 2560,
              quality: 80,
              srcSetBreakpoints: [960,1280,1920,2560]
            ) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      zucca_mascot_image_3 {
        localFile {
          childImageSharp {
            fluid (
              maxWidth: 1600,
              quality: 80,
              srcSetBreakpoints: [960,1280,1920,2560]
            ) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      whats_next_image {
        localFile {
          childImageSharp {
            fluid (
              maxWidth: 1600,
              quality: 80,
              srcSetBreakpoints: [960,1280,1920,2560]
            ) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      work_image {
        localFile {
          childImageSharp {
            fluid (
              maxWidth: 1600,
              quality: 80,
              srcSetBreakpoints: [960,1280,1920,2560]
            ) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      housing_lightbox {
        image {
          localFile {
            childImageSharp {
              fluid (
                maxWidth: 1600,
                quality: 80,
                srcSetBreakpoints: [960,1280,1920,2560]
              ) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        logos_repeater {
          image {
            localFile {
              childImageSharp {
                fluid (
                  maxWidth: 1600,
                  quality: 80,
                  srcSetBreakpoints: [960,1280,1920,2560]
                ) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          link
        }
      }
      campus_life_lightbox {
        dummy
        image1 {
          localFile {
              childImageSharp {
                fluid (
                  maxWidth: 1600,
                  quality: 80,
                  srcSetBreakpoints: [960,1280,1920,2560]
                ) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
        }
        image2 {
          localFile {
              childImageSharp {
                fluid (
                  maxWidth: 1600,
                  quality: 80,
                  srcSetBreakpoints: [960,1280,1920,2560]
                ) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
        }
        image3 {
          localFile {
              childImageSharp {
                fluid (
                  maxWidth: 1600,
                  quality: 80,
                  srcSetBreakpoints: [960,1280,1920,2560]
                ) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
        }
      }
    }
  }
  }
`



