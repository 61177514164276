import './style.scss'
import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import Intro from '../../../animations/Intro'
import { useInView } from 'react-intersection-observer'

export default function ISImageLinks({ image_links_section }) {
  const [io, ioInView] = useInView({ triggerOnce: true })


  return (
    <div ref={io} id="is-image-links" className="c5 t">
      <div className='flex'>
        {image_links_section?.map((item, i) => {
          return (
					item?.link ?
            <a key={i} href={item?.link} target='_blank' rel='noopener noreferrer' className='single-person' >
              <Intro visible={ioInView} in={{ fadeRight: 500 }} delayIn={250 + 75 * i} mounted={true} stay={true} >
                <div style={{ paddingBottom: "84%" }} className='aspect-ratio img'>
                  <Img fluid={item.image.localFile.childImageSharp.fluid}
                    imgStyle={{ objectFit: 'cover' }}
                    objectPosition='50% 50%'
                    className=''
                  />
                  <div className='copy'>
                    <div className='heading padd-2'>
                      <h4 className='h3'>{item.title}</h4>
                    </div>
                  </div>
                </div>
              </Intro>
            </a>
						:
						<div key={i}  className='single-person' >
						<Intro visible={ioInView} in={{ fadeRight: 500 }} delayIn={250 + 75 * i} mounted={true} stay={true} >
							<div style={{ paddingBottom: "84%" }} className='aspect-ratio img'>
								<Img fluid={item.image.localFile.childImageSharp.fluid}
									imgStyle={{ objectFit: 'cover' }}
									objectPosition='50% 50%'
									className=''
								/>
								<div className='copy'>
									<div className='heading padd-2'>
										<h4 className='h3'>{item.title}</h4>
									</div>
								</div>
							</div>
						</Intro>
					</div>
          )
        })}
      </div>
    </div>
  )
}